<template>
  <div class="booking-signature-details-page">
    <back-title @click="goBack">{{ $t("Signature details") }}</back-title>
    <Loader v-if="isLoading" color="primary" size="m" class="mx-auto" />
    <div class="empty" v-else-if="isError">
      {{ $t("An error occurred") }}. {{ $t("Repeat the request later") }}
    </div>
    <SignatureDetails v-else :signature="signature" />
  </div>
</template>
<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import SignatureDetails from "@/components/signatures/SignatureDetails";

export default {
  name: "BookingSignatureDetailsPage",
  components: { SignatureDetails, BackTitle },
  data: () => ({
    isLoading: false,
    isError: false,
  }),
  async created() {
    try {
      this.isLoading = true;
      await this.getSignatureById(this.$route.params.signatureId);
    } catch {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    ...mapState({
      booking: (state) => state.bookings.editedItem,
      venue: (state) => state.venues.selectedVenue,
      signature: (state) => state.bookings.detailedSignature,
    }),
  },
  methods: {
    ...mapActions({
      getSignatureById: "bookings/fetchSignatureById",
      deleteSignature: "signatures/deleteSignature",
      exportSignatureToCSV: "signatures/exportSignatureToCSV",
    }),
    goBack() {
      this.$router.push({
        name: "BookingSignatures",
      });
    },
  },
};
</script>

<style lang="scss">
.booking-signature-details-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
